import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React, { Component } from 'react'
import Hoverable from '../../components/hoverable/Hoverable'
import SEO from '../../components/seo'
import Wysiwyg from '../../components/wysiwyg/Wysiwyg'
import Layout from '../../layouts/default/layout'
import OfferingList from '../../components/offering-list/OfferingList'
import './about.scss'

export default class AboutTemplate extends Component {
    render() {
        const { page, offerings, team } = this.props.data
        const {
            heading,
            content,
            service_listing,
            visualBreak,
            visualBreakMobile,
            offerings_title,
        } = page.acf
        return (
            <Layout className="AboutPage">
                <SEO
                    yoast={page.yoast}
                    title={page.title}
                    description={page.content}
                    dateModified={page.modified}
                    datePublished={page.date}
                />
                <main>
                    <section className="about-content container-fluid px-yd mt-yd mb-yd">
                        <div className="row">
                            <div className="col-12 col-md-11 col-lg-8">
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: heading,
                                    }}
                                />
                                <div className="image-container">
                                    <Img
                                        className="d-mobile"
                                        fluid={
                                            page.featuredMediaMobile.localFile
                                                .childImageSharp.fluid
                                        }
                                        alt={page.featuredMedia.alt_text}
                                        title={page.featuredMedia.title}
                                    />
                                    <Img
                                        className="d-non-mobile"
                                        fluid={
                                            page.featuredMedia.localFile
                                                .childImageSharp.fluid
                                        }
                                        alt={page.featuredMedia.alt_text}
                                        title={page.featuredMedia.title}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-4 col-lg-6 offset-lg-6">
                                <Wysiwyg className="about-description type-subtitle">
                                    {content}
                                </Wysiwyg>
                            </div>
                        </div>
                    </section>
                    <section className="about-services container-fluid px-yd">
                        <ul className="services-list list-unstyled row">
                            {service_listing.map(
                                ({ title, description }, index) => (
                                    <li
                                        key={index}
                                        className="services-list-item col-12 col-md-6 col-lg-4 mb-yd"
                                    >
                                        <h3>{title}</h3>
                                        <Wysiwyg className="text-secondary">
                                            {description}
                                        </Wysiwyg>
                                    </li>
                                )
                            )}
                        </ul>
                    </section>
                    <Img
                        fluid={
                            visualBreakMobile.localFile.childImageSharp.fluid
                        }
                        className="mb-yd d-mobile"
                        alt={visualBreak.alt_text}
                        title={visualBreak.title}
                    />
                    <Img
                        fluid={visualBreak.localFile.childImageSharp.fluid}
                        className="mb-yd d-non-mobile"
                        alt={visualBreak.alt_text}
                        title={visualBreak.title}
                    />
                    <OfferingList
                        offerings_title={offerings_title}
                        offerings={offerings}
                        pageSlug={page.slug} //Typically 'about'
                    />
                    <section className="team-container container-fluid px-yd mb-yd">
                        <div className="row mb-yd mt-yd">
                            <div className="col-12">
                                <h2>{`Our team`}</h2>
                            </div>
                        </div>
                        <ul className="team-list row list-unstyled">
                            {team.edges.map(({ node: teamMemberPage }) => (
                                <li
                                    key={teamMemberPage.id}
                                    className="team-list-item col-6 col-lg-3"
                                >
                                    <Link
                                        style={{ display: 'block' }}
                                        to={`/${page.slug}/team/${
                                            teamMemberPage.slug
                                        }`}
                                    >
                                        <Hoverable.Container>
                                            {teamMemberPage.featuredMedia && (
                                                <Hoverable.Overlay className="mb-2">
                                                    <Img
                                                        fluid={
                                                            teamMemberPage
                                                                .featuredMedia
                                                                .localFile
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                        alt={
                                                            teamMemberPage
                                                                .featuredMedia
                                                                .alt_text
                                                        }
                                                        title={
                                                            teamMemberPage
                                                                .featuredMedia
                                                                .title
                                                        }
                                                    />
                                                </Hoverable.Overlay>
                                            )}
                                            <h3
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        teamMemberPage.title,
                                                }}
                                            />
                                            <div className="type-label text-secondary">
                                                {teamMemberPage.acf.title}
                                            </div>
                                        </Hoverable.Container>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </section>
                </main>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            slug
            title
            content
            ...YoastPage
            featuredMedia: featured_media {
                alt_text
                title
                localFile {
                    childImageSharp {
                        fluid(quality:90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            featuredMediaMobile: featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1024, maxHeight: 1024 quality:90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            acf {
                heading
                content
                service_listing {
                    title
                    description
                }
                visualBreak: visual_break {
                    alt_text
                    title
                    localFile {
                        childImageSharp {
                            fluid(quality:95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                visualBreakMobile: visual_break {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1024, maxHeight: 1024) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                offerings_title
            }
        }
        offerings: allWordpressPage(
            filter: { parent_element: { slug: { eq: "offerings" } } }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    featuredMedia: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1024, maxHeight: 1024) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    acf {
                        key_services
                    }
                }
            }
        }
        team: allWordpressPage(
            filter: { parent_element: { slug: { eq: "team" } } }
            sort: {
            fields: menu_order
            order: ASC          
            }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    featuredMedia: featured_media {
                        alt_text
                        title
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 900
                                    maxHeight: 1080
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    acf {
                        title
                    }
                }
            }
        }
    }
`
